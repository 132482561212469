import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { UserDto } from '../../../../../../../../types/dto/user.dto';
import { AnnualLeaveRequestDto } from '../../../../../../../../types/dto/work-logs.dto';

interface Props {
  request: AnnualLeaveRequestDto;
  employee: UserDto;
}

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  wrapper: {
    fontFamily: 'Roboto',
    padding: '20px 70px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px 0',
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 10,
  },
  signature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '20px',
    marginTop: 60,
    fontSize: 12,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
});

const RequestBody = ({ request, employee }: Props) => {
  const { t } = useTranslation('work-logs');

  return (
    <View style={styles.wrapper}>
      <View>
        <Text style={styles.title}>{t('alr.pdf.title')}</Text>
      </View>
      <View style={styles.body}>
        <View style={styles.section}>
          <Text>
            {t('alr.pdf.employee_full_name')}:{' '}
            {employee.givenName + ' ' + employee.familyName}
          </Text>
        </View>

        <View style={styles.section}>
          <Text>
            {t('alr.pdf.start')}:{' '}
            {dayjs(request.startDate).format('DD.MM.YYYY. ')}
          </Text>
          <Text>
            {t('alr.pdf.end')}: {dayjs(request.endDate).format('DD.MM.YYYY. ')}
          </Text>
        </View>

        <View style={styles.section}>
          <Text>
            {t('alr.pdf.location')}: {t(`alr.location.${request.location}`)}
          </Text>
        </View>
      </View>

      <View style={styles.signature}>
        <Text>{t('alr.pdf.approved_by')}:</Text>
        <Text>________________________</Text>
      </View>
    </View>
  );
};

export default RequestBody;
