import {
  StyleSheet,
  Document,
  Page,
  View,
  Image,
  Text,
} from '@react-pdf/renderer';

import { UserDto } from '../../../../../../../types/dto/user.dto';
import { AnnualLeaveRequestDto } from '../../../../../../../types/dto/work-logs.dto';
import RequestBody from './request-body/request-body.component';

interface Props {
  user: UserDto;
  request: AnnualLeaveRequestDto;
}
const AnnualLeaveRequestPDF = (props: Props) => {
  const styles = StyleSheet.create({
    page: {
      paddingBottom: 100,
    },
    logo: { height: '20px', maxWidth: '100px' },
    logoRowCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '20px 0',
    },
    requestFooter: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 40,
      borderTop: '1px solid #ccc',
      paddingTop: '10px',
      textAlign: 'center',
      fontSize: 8,
    },
    requestCode: {
      border: '0.5px solid #000',
      padding: 2,
      margin: '10px 60px 0px 60px',
      fontFamily: 'Roboto',
      fontSize: 10,
      width: '64px',
    },
  });

  return (
    <Document>
      <Page size='A4' style={styles.page} wrap>
        <View style={styles.logoRowCenter}>
          <Image source={'/assets/aspira-uciliste.png'} style={styles.logo} />
        </View>
        <View style={styles.requestCode}>
          <Text>ASP-053 v2.</Text>
        </View>
        <View>
          <RequestBody request={props.request} employee={props.user} />
        </View>
        <View style={styles.requestFooter} fixed>
          <Text>
            Domovinskog rata 65 • 21000 Split • Tel: 021/382-802 • Fax:
            021/382-805
          </Text>
          <Text>
            OIB: 14885934105 • IBAN: HR4024840081104992880 • E-mail:
            info@aspira.hr • www.aspira.hr
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default AnnualLeaveRequestPDF;
